import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import _ from 'lodash';
import APPCONFIG from '../../../app-config.js';
// import { Link } from 'react-router-dom';
import { Row, Col, Input, Card, CardTitle, CardBody, Nav, NavItem, NavLink, Button } from 'reactstrap';
import TinyEditor from '@components/TinyEditor';
import ModalWrapper from '@components/ModalWrapper';
import './obituaries.css';

const { API_URI, API_ROUTES } = APPCONFIG;

class Obituaries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiLoadingObituaries: true,
      ObituariesData: {},
      ObituariesElems: {},
      modal: {
        show: false,
        title: 'Create/Add an Obituaries',
      },
      editor: {
        msgObj: {},
        initialData: '',
        padding: 230
      }
    };
  }

  _tinyMCE = React.createRef();

  get tinyMCE() {
    return this._tinyMCE.current;
  }

  componentDidMount() {
    this.getObituaries();
  }

  getObituaries() {
    fetch(`${API_URI}${API_ROUTES.OBITUARIES.GET}`)
      .then(r => r.json())
      .then(ObituariesData => {
        this.setState({ ObituariesData, apiLoadingObituaries: false });
      })
      .catch((error) => {
        this.setState({ apiError: error, apiLoadingObituaries: false });
      });
  }

  createObituaries() {
    const details = {};
    const short_lines = _.get(this.state, 'short_lines', '');
    details.bodyHTML = this.tinyMCE.editor.getContent({ format: 'html' });
    details.bodyText = this.tinyMCE.editor.getContent({ format: 'text' });

    const tempData = {
      short_lines,
      details,
      image: ''
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(tempData));
    console.log(tempData, JSON.stringify(tempData), `${API_URI}${API_ROUTES.OBITUARIES.CREATE}`);

    fetch(`${API_URI}${API_ROUTES.OBITUARIES.CREATE}`, {
      method: 'POST',
      body: formData
    })
      .then(r => r.text())
      .then(() => {
        // re-renderering the component to reflect the changes
        this.getObituaries();
        this.closeModal();
      });
  }

  deleteObituaries(id) {

    const formData = new FormData();
    formData.append('id', id);
    console.log(formData.get('id'), 'formadata');

    fetch(`${API_URI}${API_ROUTES.OBITUARIES.DELETE}`, {
      method: 'POST',
      body: formData
    })
      .then(() => console.log('Deleted......'))
      .then(() => this.getObituaries())
      .catch(error => console.log('error while deleting Announcement', error));
  }

  render() {
    return (
      <React.Fragment>
        {this._renderNavOptions()}
        <div className="container-fluid">
          {this._renderObituariesModal()}
          {this.state.ObituariesData.length > 0 ? this._renderObituaries() : this._renderEmptyMessage()}
        </div>
      </React.Fragment>
    );
  }

  _renderNavOptions() {
    return (
      <Nav className="nav-options">
        <NavItem>
          <NavLink href="#" className="btn btn-secondary" onClick={this.showModal}>Add Obituaries</NavLink>
        </NavItem>
      </Nav>
    );
  }

  _renderObituariesModal() {
    const { modal, editor } = this.state;
    return (
      <ModalWrapper
        visible={modal.show}
        modalTitle={modal.title}
        modalBody={modal.body}
        submitBtnLabel={modal.submitBtnLabel}
        cancelBtnLabel="Cancel"
        onSubmitClick={this.onSubmitClick}
        onCancelClick={this.closeModal}
      >
        <div className="label">In One Line</div>
        <Input type="text" name="short_lines" placeholder="with a placeholder" required onChange={this.onInputChange} value={this.state.short_lines} autoComplete="off"/>
        <div className="label">In Detail</div>
        <TinyEditor ref={this._tinyMCE} height={`${window.height - editor.padding}px`} initialData={editor.initialData} />
        <div className="label">Image</div>
        <Input type="file" name="file" id="exampleFile" />
        <small>This is will be used as an image placeholder for this news under the details of Announcements.</small>
      </ModalWrapper>
    );
  }

  _renderObituaries() {
    return (
      <Row>
        {_.map(this.state.ObituariesData, (obj) => {
          return (
            <Col sm="6" md="4" key={obj.id}>
              <Button className="cardDeleteBtn" onClick={() => this.onDeleteBtnClick(obj)}><span aria-hidden="true"><i className="fas fa-trash-alt"></i></span></Button>
              <Card body className="mb-3 btn" role="button" onClick={() => this.onObituariesTileClick(obj)}>
                <CardTitle tag="h5" className="text-center">{obj.short_lines}</CardTitle>
                <CardBody className="announcements-card-body" dangerouslySetInnerHTML={{ __html: obj.details.bodyHTML }}/>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }

  _renderEmptyMessage() {
    return (
      <div className="container h-100 d-flex justify-content-center">
        <div className="jumbotron my-auto">
          <h1 className="display-4">
            {this.state.apiLoadingObituaries ? <Spinner style={{ width: '3rem', height: '3rem' }} /> :
              this.state.apiError ? 'API Server is not reachable' : 'No Obituaries'}</h1>
        </div>
      </div>
    );
  }

  onInputChange = (event) => {
    console.log('onInputChange', event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSubmitClick = () => {
    this.createObituaries();
  }

  onObituariesTileClick = (tileObj) => {
    this.showModal(tileObj);
  }

  onDeleteBtnClick = (tileObj) => {
    this.deleteObituaries(tileObj.id);
  }

  showModal = (tileObj) => {
    const { modal, editor } = this.state;
    let short_lines;
    modal.show = true;
    editor.padding = this.state.editor.padding;
    // if this is edit modal
    if (tileObj.id) {
      modal.title = 'Edit Obituaries';
      modal.submitBtnLabel = 'Update';
      editor.initialData = tileObj.details.bodyHTML;
      editor.msgObj = tileObj.details;
      short_lines = tileObj.short_lines;
    } else {
      modal.title = 'Create/Add Obituaries';
      modal.submitBtnLabel = 'Create';
      editor.initialData = '';
      editor.msgObj = '';
      short_lines = '';
    }
    this.setState({ modal, editor, short_lines });
  }

  closeModal = () => {
    const { modal } = this.state;
    modal.show = false;
    this.setState({ modal });
  }
}

export default Obituaries;