/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalWrapper = (props) => {
  const {
    width,
    height,
    visible,
    className,
    children,
    modalTitle,
    modalBody,
    submitBtnLabel,
    cancelBtnLabel,
    onSubmitClick,
    onCancelClick,
    submitBtnClass,
    cancelBtnClass
  } = props;

  return (
    <div>
      <Modal size="xl" isOpen={visible} className={className} style={{ width: width, height: height }}>
        <ModalHeader tag="h3" className="text-center" style={{ display: 'initial'}}>{modalTitle}</ModalHeader>
        <ModalBody>{children || modalBody}</ModalBody>
        <ModalFooter>
          <Button color={`primary ${submitBtnClass}`} onClick={onSubmitClick}>{submitBtnLabel}</Button>{' '}
          <Button color={`secondary ${cancelBtnClass}`} onClick={onCancelClick}>{cancelBtnLabel}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalWrapper;