const APPCONFIG = {
  API_URI: 'http://api.kalyancathedral.org/public/api',
  API_ROUTES: {
    ANNOUNCEMENTS: {
      CREATE: '/annoucements/create',
      GET: '/annoucements/get',
      DELETE: '/annoucements/delete'
    },
    MESSAGES: {
      UPDATE: '/messages/update',
      GET: '/messages/get',
      DELETE: '/messages/create'
    },
    MARRIAGEBANS: {
      CREATE: '/marriage-bans/create',
      GET: '/marriage-bans/get',
      DELETE: '/marriage-bans/delete'
    },
    GOLDENJUBILEE: {
      CREATE: '/golden-jubilee/create',
      GET: '/golden-jubilee/get',
      DELETE: '/golden-jubilee/delete'
    },
    WEDDINGANNIVERSARY: {
      CREATE: '/wedding-anniversary/create',
      GET: '/wedding-anniversary/get',
      DELETE: '/wedding-anniversary/delete'
    },
    OBITUARIES: {
      CREATE: '/obituaries/create',
      GET: '/obituaries/get',
      DELETE: '/obituaries/delete'
    },
    PRAYERS: {
      CREATE: '/prayers/create',
      GET: '/prayers/get',
      DELETE: '/prayers/delete'
    }
  }
};

export default APPCONFIG;