/* eslint-disable react/prop-types */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = React.forwardRef((props, ref) => {
  const {
    initialData,
    height,
    onChange
  } = props;

  return (
    <Editor
      ref={ref}
      apiKey='k6j1kb0zbt2cg0qy7pohxm20wdxetln9tio198cll8mhh0ow'
      initialValue={initialData}
      onChange={onChange}
      init={{ height: height }}
    ></Editor>
  );
});

export default TinyEditor;