import React from 'react';
import _ from 'lodash';
import { Row, Col, Card, CardTitle, CardBody, Button } from 'reactstrap';
import BaseView from '@views/BaseView';
import './Messages.css';
import ModalWrapper from '@components/ModalWrapper';
import TinyEditor from '@components/TinyEditor';
import APPCONFIG from '../../../app-config.js';

const { API_URI, API_ROUTES } = APPCONFIG;

class Messages extends BaseView {
  constructor(props) {
    super(props);

    this.state = {
      collapse_add: false,
      collapse_view: true,
      messagesData: {},
      messagesElems: {},
      window: {
        width: 600,
        height: 500
      },
      modal: {
        show: false,
        title: 'Default Title',
        body: 'Body',
      },
      editor: {
        msgObj: {},
        initialData: '',
        padding: 230
      }
    };
  }

  _tinyMCE = React.createRef();

  get tinyMCE() {
    return this._tinyMCE.current;
  }

  componentDidMount() {
    this.getMessages();
    const window = this.getWindowSize();
    this.setState({ window });
  }

  updateMessages(msgObj) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(msgObj));
    formData.append('from', msgObj.from);

    fetch(`${API_URI}${API_ROUTES.MESSAGES.UPDATE}`, {
      method: 'POST',
      body: formData
    })
      .then(r => r.text())
      .then(() => {
        // re-renderering the component to reflect the changes
        this.getMessages();
      });
  }

  getMessages() {
    fetch(`${API_URI}${API_ROUTES.MESSAGES.GET}`)
      .then(r => r.json())
      .then(messagesData => {
        const messagesElems = _.map(messagesData, (msgObj) => {
          return (
            <Button key={msgObj.from} className="messageButton" onClick={() => this.showModal(msgObj)}>
              <Card body className="mb-3">
                <CardTitle tag="h5">{msgObj.headerTitle}</CardTitle>
                <CardBody>{msgObj.headerSubTitle}</CardBody>
              </Card>
            </Button>
          );
        });
        this.setState({ messagesData, messagesElems });
      });
  }

  render() {
    const { messagesElems, messagesData, window, modal, editor } = this.state;
    return (
      <div className="container-fluid">
        <Row>
          {_.map(messagesElems, (messages, index) => (
            <Col md="4" key={messagesData[index].id}>{messages}</Col>)
          )}
        </Row>
        <ModalWrapper
          visible={modal.show}
          modalTitle={modal.title}
          modalBody={modal.body}
          submitBtnLabel="Submit"
          cancelBtnLabel="Cancel"
          onSubmitClick={this.onSubmitClick}
          onCancelClick={this.closeModal}
        >
          <TinyEditor ref={this._tinyMCE} height={`${window.height - editor.padding}px`} initialData={editor.initialData} />
        </ModalWrapper>
      </div>
    );
  }


  showModal = (msgObj) => {
    this.setState({
      modal: {
        show: true,
        title: msgObj.headerTitle
      },
      editor: {
        msgObj,
        initialData: msgObj.bodyHTML,
        padding: this.state.editor.padding
      }
    });
    console.log(msgObj.bodyHTML, this.state.modalShow);
  }

  closeModal = () => {
    this.setState({ modal: { show: false } });
  }

  getWindowSize = () => {
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    const height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

    return { width, height };
  }

  onSubmitClick = () => {
    const msgObj = this.state.editor.msgObj;
    console.log(msgObj, 'msgObj');
    msgObj.bodyHTML = this.tinyMCE.editor.getContent({ format: 'html' });
    msgObj.bodyText = this.tinyMCE.editor.getContent({ format: 'text' });
    this.updateMessages(msgObj);    
  }


}

export default Messages;