import React, { Component } from 'react';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import '../../css/Home.css';
import routes from '../../routes';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getView(view) {
    const obj = _.find(routes.HomePage, { 'matchPath': view });
    this.viewComponentTitle = obj.title;
    console.log(this.viewComponentTitle, 'obj.matchPath');
    return <obj.view />;
  }

  render() {
    return (
      <div className="wrapper">
        <div className="sidebar">
          <div className="logo"><Link to="/home/dashboard" className="simple-text logo-normal">Cathedral CMS</Link> </div>
          <div className="sidebar-wrapper">
            <Nav vertical>
              <NavItem>
                <NavLink className="nav-link" to="">Link</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to="" >Link</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to="" >Another Link</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to="" disabled >Disabled Link</NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="main-panel">
          <Navbar color="light" light expand="md">
            <NavbarBrand to="/">{_.startCase(this.props.match.params.view)}</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link" to="/login">Logout</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
          <div className="content"> {this.getView(this.props.match.params.view)} </div>
        </div>
      </div>
    );
  }
}

export default HomePage;