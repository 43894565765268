import Dashboard from './views/HomePage/Dashboard';
import Announcements from './views/HomePage/Announcements';
import Messages from './views/HomePage/Messages';
import MarriageBans from './views/HomePage/MarriageBans';
import GoldenJubilee from './views/HomePage/GoldenJubilee';
import WeddingAnniversary from './views/HomePage/WeddingAnniversary';
import Obituaries from './views/HomePage/Obituaries';
import Prayers from './views/HomePage/Prayers';

const routes = {
  'HomePage': [
    { matchPath: 'dashboard', title: 'Dashboard', view: Dashboard },
    { matchPath: 'announcements', title: 'Announcements', view: Announcements },
    { matchPath: 'messages', title: 'Messages', view: Messages },
    { matchPath: 'marriage-bans', title: 'Marriage-Bans', view: MarriageBans },
    { matchPath: 'golden-jubilee', title: 'Golden-Jubilee', view: GoldenJubilee },
    { matchPath: 'wedding-anniversary', title: 'Wedding-Anniversary', view: WeddingAnniversary },
    { matchPath: 'obituaries', title: 'Obituaries', view: Obituaries },
    { matchPath: 'prayers', title: 'Prayers', view: Prayers }
  ]
};

export default routes;