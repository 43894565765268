import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import '../css/App.css';
import LoginPage from '../views/LoginPage';
import HomePage from '../views/HomePage';

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <div className="container-fluid"> */}
        <HashRouter basename="/cathedral-cms">
          <Switch>
            {/* <Route path="/404" exact component={Page_404} />
              <Route path="/500" exact component={Page_500} /> */}
            <Route path="/login" exact component={LoginPage} />
            <Route path="/home/:view" exact component={HomePage} />
            <Redirect from="/" to="/login" />
          </Switch>
        </HashRouter>
        {/* </div> */}
      </div>
    );
  }
}

export default App;
