import React, { Component } from 'react';
import { Row, Col, Button, Card, CustomInput, CardBody, Form, FormGroup, Input } from 'reactstrap';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    if (btoa(username + password) === 'am95YW5pc2gxOTkzQGdtYWlsLmNvbWFuaXNoQDEyMw==') {
      console.log('credentails maatched');
      this.props.history.push('/home/dashboard');
    } else {
      console.log('wrong credentails');
    }
  }

  handleChange(event, type) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [`${type}`]: value });
  }

  render() {
    return (
      <div className="jumbotron vertical-center login-wrapper mx-auto">
        <div className="container">
          <Row className="h-100 row align-items-center">
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Card className="login-form">
                <h5 className="card-title text-center">Cathedral CMS</h5>
                <CardBody>
                  <Form className="form-signin" onSubmit={this.onSubmit}>
                    <FormGroup>
                      <Input type="email" onChange={(e) => { this.handleChange(e, 'username'); }} className="form-control" placeholder="Email address" required autoFocus />
                    </FormGroup>
                    <FormGroup>
                      <Input type="password" onChange={(e) => { this.handleChange(e, 'password'); }} className="form-control" placeholder="Password" required />
                    </FormGroup>
                    <div className="mb-3">
                      <CustomInput type="checkbox" id="remmberMeCheckbox" onChange={(e) => { this.handleChange(e, 'remmberMeCheckbox'); }} label="Remember password" />
                    </div>
                    <Button type="submit" color="primary">Sign in</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default LoginPage;