import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import _ from 'lodash';
import APPCONFIG from '../../../app-config.js';
// import { Link } from 'react-router-dom';
import { Row, Col, Input, Card, CardTitle, CardBody, Nav, NavItem, NavLink, Button } from 'reactstrap';
import ModalWrapper from '@components/ModalWrapper';
import './Prayers.css';

const { API_URI, API_ROUTES } = APPCONFIG;

class Prayers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiLoadingPrayers: true,
      prayersData: {},
      annoucementsElems: {},
      modal: {
        show: false,
        title: 'Create/Add an Prayer',
      },
      editor: {
        msgObj: {},
        initialData: '',
        padding: 230
      }
    };
  }

  _tinyMCE = React.createRef();

  get tinyMCE() {
    return this._tinyMCE.current;
  }

  componentDidMount() {
    this.getAnnoucements();
  }

  getAnnoucements() {
    fetch(`${API_URI}${API_ROUTES.PRAYERS.GET}`)
      .then(r => r.json())
      .then(prayersData => {
        this.setState({ prayersData, apiLoadingPrayers: false });
      })
      .catch((error) => {
        this.setState({ apiError: error, apiLoadingPrayers: false });
      });
  }

  createPrayer() {
    const prayer_name = _.get(this.state, 'prayer_name', '');
    const prayer_type = _.get(this.state, 'prayer_type', '');
    const en_filename = _.get(this.state, 'en_filename', '');
    const mal_filename = _.get(this.state, 'mal_filename', '');

    const tempData = {
      prayer_name,
      prayer_type,
      en_filename,
      mal_filename
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(tempData));
    console.log(tempData, JSON.stringify(tempData), `${API_URI}${API_ROUTES.PRAYERS.CREATE}`);

    fetch(`${API_URI}${API_ROUTES.PRAYERS.CREATE}`, {
      method: 'POST',
      body: formData
    })
      .then(r => r.text())
      .then(() => {
        // re-renderering the component to reflect the changes
        this.getAnnoucements();
        this.closeModal();
      });
  }

  deletePrayer(id) {

    const formData = new FormData();
    formData.append('id', id);
    console.log(formData.get('id'), 'formadata');

    fetch(`${API_URI}${API_ROUTES.PRAYERS.DELETE}`, {
      method: 'POST',
      body: formData
    })
      .then(() => console.log('Deleted......'))
      .then(() => this.getAnnoucements())
      .catch(error => console.log('error while deleting Announcement', error));
  }

  render() {
    return (
      <React.Fragment>
        {this._renderNavOptions()}
        <div className="container-fluid">
          {this._renderPrayerModal()}
          {this.state.prayersData.length > 0 ? this._renderPrayers() : this._renderEmptyMessage()}
        </div>
      </React.Fragment>
    );
  }

  _renderNavOptions() {
    return (
      <Nav className="nav-options">
        <NavItem>
          <NavLink href="#" className="btn btn-secondary" onClick={this.showModal}>Add Prayers</NavLink>
        </NavItem>
      </Nav>
    );
  }

  _renderPrayerModal() {
    const { modal } = this.state;
    return (
      <ModalWrapper
        visible={modal.show}
        modalTitle={modal.title}
        modalBody={modal.body}
        submitBtnLabel={modal.submitBtnLabel}
        cancelBtnLabel="Cancel"
        onSubmitClick={this.onSubmitClick}
        onCancelClick={this.closeModal}
      >
        <div className="label">Prayer Name</div>
        <Input type="text" name="prayer_name" placeholder="with a placeholder" required onChange={this.onInputChange} value={this.state.prayer_name} autoComplete="off"/>
        <div className="label">Prayer Type</div>
        <Input type="text" name="prayer_type" placeholder="with a placeholder" required onChange={this.onInputChange} value={this.state.prayer_type} autoComplete="off" />
        <div className="label">Path for English file</div>
        <Input type="text" name="en_filename" placeholder="with a placeholder" required onChange={this.onInputChange} value={this.state.en_filename} autoComplete="off" />
        <div className="label">Path for Malayalam file</div>
        <Input type="text" name="mal_filename" placeholder="with a placeholder" required onChange={this.onInputChange} value={this.state.mal_filename} autoComplete="off" />
        <small>This is will be used as an image placeholder for this news under the details of Prayers.</small>
      </ModalWrapper>
    );
  }

  _renderPrayers() {
    return (
      <Row>
        {_.map(this.state.prayersData, (obj) => {
          return (
            <Col sm="6" md="4" key={obj.id}>
              <Button className="cardDeleteBtn" onClick={() => this.onDeleteBtnClick(obj)}><span aria-hidden="true"><i className="fas fa-trash-alt"></i></span></Button>
              <Card body className="mb-3 btn" role="button" onClick={() => this.onPrayerTileClick(obj)}>
                <CardTitle tag="h5" className="text-center">{obj.prayer_name}</CardTitle>
                <CardBody className="Prayers-card-body">
                  English File Path: {obj.en_filename} <br/>
                  Malayalam File Path: {obj.mal_filename}
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }

  _renderEmptyMessage() {
    return (
      <div className="container h-100 d-flex justify-content-center">
        <div className="jumbotron my-auto">
          <h1 className="display-4">
            {this.state.apiLoadingPrayers ? <Spinner style={{ width: '3rem', height: '3rem' }} /> :
              this.state.apiError ? 'API Server is not reachable' : 'No Annoucements'}</h1>
        </div>
      </div>
    );
  }

  onInputChange = (event) => {
    console.log('onInputChange', event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSubmitClick = () => {
    this.createPrayer();
  }

  onPrayerTileClick = (tileObj) => {
    this.showModal(tileObj);
  }

  onDeleteBtnClick = (tileObj) => {
    this.deletePrayer(tileObj.id);
  }

  showModal = (tileObj) => {
    const { modal, editor } = this.state;
    let prayer_name;
    let prayer_type;
    let en_filename;
    let mal_filename;
    modal.show = true;
    editor.padding = this.state.editor.padding;
    // if this is edit modal
    if (tileObj.id) {
      modal.title = 'Edit Prayer';
      modal.submitBtnLabel = 'Update';
      prayer_name = tileObj.prayer_name;
      prayer_type = tileObj.prayer_type;
      en_filename = tileObj.en_filename;
      mal_filename = tileObj.mal_filename;
    } else {
      modal.title = 'Create/Add Prayer';
      modal.submitBtnLabel = 'Create';
      prayer_name = '';
      prayer_type = '';
      en_filename = '';
      mal_filename = '';
    }
    this.setState({ modal, editor, prayer_name, prayer_type, en_filename, mal_filename });
  }

  closeModal = () => {
    const { modal } = this.state;
    modal.show = false;
    this.setState({ modal });
  }
}

export default Prayers;