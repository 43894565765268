import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <Row>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/announcements">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Announcements</CardTitle>
                <CardText>Use this to add new or modify the exsiting Announcements</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/messages">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Messages</CardTitle>
                <CardText>Use this to add new or modify the exsiting Messages</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/marriage-bans">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Marriage Bans</CardTitle>
                <CardText>Use this to add new or modify the exsiting Marriage Bans</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/golden-jubilee">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Golden Jubilee</CardTitle>
                <CardText>Use this to add new or modify the exsiting Golden Jubilee</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/wedding-anniversary">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Wedding Anniversary</CardTitle>
                <CardText>Use this to add new or modify the exsiting Wedding Anniversary</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/obituaries">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Obituaries</CardTitle>
                <CardText>Use this to add new or modify the exsiting Obituaries</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col md="4" className="mb-3">
          <Link className="home-shortcuts" to="/home/prayers">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Prayers</CardTitle>
                <CardText>Use this to add new or modify the exsiting Prayers</CardText>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;